import React from 'react'
import { Router } from '@reach/router'
import RAC from '../../../templates/RAC'
import DynamicNotFoundPage from '../../dynamicNotFound'

export default () => (
  <Router>
    <RAC path="/ja/corporate-finance/*" />
    <RAC path="/zh-cn/corporate-finance/*" />
    <RAC path="/zh-tw/corporate-finance/*" />
    <RAC path="/pl/corporate-finance/*" />
    <RAC path="/ru/corporate-finance/*" />
    <RAC path="/it/corporate-finance/*" />
    <RAC path="/de/corporate-finance/*" />
    <RAC path="/es/corporate-finance/*" />
    <RAC path="/fr/corporate-finance/*" />
    <RAC path="/ar/corporate-finance/*" />
    <RAC path="/id/corporate-finance/*" />
    <RAC path="/pt/corporate-finance/*" />
    <RAC path="/ko/corporate-finance/*" />
    <DynamicNotFoundPage default />
  </Router>
)
